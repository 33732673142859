import React, { useState } from 'react';
import moment from 'moment-timezone';
import './index.scss';

const renderDate = (x, timezone) => {
    return moment(x).tz(timezone).format('HH:mm');
};

const renderDuration = (duration) => {
    const q = Math.floor(duration / 60);
    const r = duration % 60;

    return `${q !== 0 ? q : ''} ${q !== 0 ? 'ч' : ''} ${r !== 0 ? r : ''} ${r !== 0 ? 'мин' : ''}`;
};

const renderDescription = (schedule) => {
    let newDescription = schedule.description;

    const matches = newDescription.matchAll(/\[([^\[\]]*)\]\((.*?)\)/gm);
    for (const match of matches) {
        if (match[0] && match[1] && match[2]) {
            newDescription = newDescription.replace(match[0], `<a href="${match[2]}" target="_blank">${match[1]}</a>`);
        }
    }

    return newDescription.replace(/(?:\r\n|\r|\n)/g, '<br />')
};


const renderAbout = (text) => {
    let newAbout = text;

    const matches = newAbout.matchAll(/\[([^\[\]]*)\]\((.*?)\)/gm);
    for (const match of matches) {
        if (match[0] && match[1] && match[2]) {
            newAbout = newAbout.replace(match[0], `<a href="${match[2]}" target="_blank">${match[1]}</a>`);
        }
    }

    return newAbout.replace(/(?:\r\n|\r|\n)/g, '<br />');
};

const RouteTrip = ({ item, name, car, walk, house, image, about }) => {
    const [ showAll, setShowAll ] = useState(false);

    return (
        <div className='route-trip'>
            <div className="route-trip__name">{name}</div>
            <div className="route-trip__stats">
                <div className="route-trip__stats--item">
                    <div className="route-trip__stats--icon icon-car" />
                    <span>{car} км</span>
                </div>
                <div className="route-trip__stats--item">
                    <div className="route-trip__stats--icon icon-walk" />
                    <span>{walk} м</span>
                </div>
                <div className="route-trip__stats--item">
                    <div className="route-trip__stats--icon icon-house" />
                    <span>{house}</span>
                </div>
            </div>
            <div className="route-trip__image" style={{ backgroundImage: `url(${image})` }} />
            <div className="route-trip__desc" dangerouslySetInnerHTML={{ __html: renderAbout(about)}}/>
            <div className="route-trip__times">
                {item.filter((x, k) => !showAll ? k < 3 : true).map((schedule, i) => (
                    <div className='route-trip__times--item'>
                        <div className="route-trip__times--begin">{renderDate(schedule.start_time, schedule.timezone)}</div>
                        <div className="route-trip__times--desc">
                            <div className='route-trip__times--name'>{schedule.name}</div>
                            <div className='route-trip__times--time'>
                                <div className="route-trip__times--clock" />
                                <span>{renderDuration(schedule.duration)}</span>
                            </div>
                            <div className="route-trip__times--info" dangerouslySetInnerHTML={{ __html: renderDescription(schedule) }} />
                        </div>
                    </div>
                ))}
            </div>
            {!showAll && (
                <div
                    className="route-trip__show-more"
                    onClick={() => setShowAll(true)}
                >
                    <span>Показать весь маршрут</span>
                    <div className="arrow-down" />
                </div>
            )}
        </div>
    );
};

export default RouteTrip;
