import React from 'react';
import {wordPad} from "../../utils/_functions";

const RouteInfo = ({ image, title, day, distance, desc, recommendList, is_favourite, onFavourite }) => {
    return (
        <>
            <div className="routes-favorite__item routes-favorite__bg" style={{ backgroundImage: `url(${image})` }}>
                <div className={`routes-favorite__item--fav ${is_favourite ? 'active' : ''}`} onClick={onFavourite}>
                    <div className='icon-fav' />
                </div>
                {/*<div className="routes-favorite__item--add">*/}
                {/*    <div className='icon-fav' />*/}
                {/*    маршрут добавлен в избранное*/}
                {/*</div>*/}
            </div>
            <div className="routes-info__name" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="routes-info__days">{day} {wordPad(day, ' день', ' дня', ' дней')}</div>
            <div className="routes-info__days">{distance} км</div>
            <div className="routes-info__recommend">
                <span>Рекомендуем:</span>
                <ul>
                    {recommendList
                        .filter((rec) => rec !== '' && rec !== '\n'  && rec !== '\r' && rec !== '\n\r')
                        .map((rec) => !!rec &&
                            (
                                <li>{rec}</li>
                            )
                        )
                    }
                </ul>
            </div>
            <div className="routes-info__desc" dangerouslySetInnerHTML={{ __html: desc }} />
        </>
    );
};

export default RouteInfo;
