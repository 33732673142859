import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelHeader } from "@vkontakte/vkui";
import "./index.scss";

import { openStory, openModal } from "../../store/navigation/actions";

import HomeCard from "../../components/HomeCard";
import HomeUser from "../../components/HomeUser";

import trip from "../../assets/img/bg-trip.jpg";
import bonus from "../../assets/img/bg-bonuses.jpg";
import gifts from "../../assets/img/bg-gifts.jpg";

import fireEvent from "./../../utils/fireEvent";

import Icon28Profile from "@vkontakte/icons/dist/28/profile";
import GA from "react-ga";
import { bridgeSetStatusBar, isDesktopWeb } from "mini-juice/vk";

const Home = ({ id }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.data);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  // useEffect(() => {
  //     bridgeSetStatusBar({
  //         status_bar_style: 'dark',
  //         action_bar_color: '#ffffff',
  //         navigation_bar_color: '#ffffff',
  //     });
  // },[]);

  const getCard = () => {
    GA.event({
      category: "home",
      action: "click-get-card",
    });

    if (isDesktopWeb()) {
      fireEvent("https://www.gpnbonus.ru/on_the_way/how_to_start/");
    } else {
      fireEvent("https://m.gpnbonus.ru/how_to_start/");
    }
  };

  return (
    <Panel id={id}>
      <PanelHeader separator={false}>
        <div
          className="home__header"
          onClick={() => dispatch(openStory("profile"))}
        >
          <div className="logo" />
          <Icon28Profile fill="#C4C4C4" />
        </div>
      </PanelHeader>
      {user ? (
        <HomeUser avatar={user.photo_200} name={user.first_name} />
      ) : null}

      <div className="home__cards">
        <HomeCard
          title="Маршруты"
          image={trip}
          onClick={() =>
            dispatch(openStory(`routes${!user.gpnPhone ? "-login" : ""}`))
          }
        />
        <HomeCard
          title="Бонусы"
          image={bonus}
          onClick={() => dispatch(openStory("bonuses"))}
        />
        <HomeCard
          title="Розыгрыш"
          image={gifts}
          onClick={() => dispatch(openStory("giveaway"))}
        />
      </div>
      <div className="home__downloadApp">
        <div className="block-wrap">
          <div className="btn btn-primary" onClick={getCard}>
            оформить карту «Нам по пути»
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default Home;
