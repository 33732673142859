import React from 'react';
import bridge from "@vkontakte/vk-bridge";
import { useDispatch } from 'react-redux';
import { ModalPage, Cell, Div, List } from '@vkontakte/vkui';

import { closeModal } from '../../../store/navigation/actions';

import ModalPageHeaderWithButtons from '../ModalPageHeaderWithButtons';

import Icon28StoryOutline from '@vkontakte/icons/dist/28/story_outline';
import Icon24ShareOutline from '@vkontakte/icons/dist/24/share_outline';

const Share = ({ id }) => {
    const dispatch = useDispatch();

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={
                <ModalPageHeaderWithButtons>
                    Поделиться
                </ModalPageHeaderWithButtons>
            }
        >
            <Div>
                <List>
                    <Cell
                        before={<Icon28StoryOutline />}
                        onClick={() => {
                            bridge.send('VKWebAppShowStoryBox', {
                                background_type: 'image',
                                url: `${window.location.origin}/assets/story.png`,
                                locked: true,
                                attachment: {
                                    text: 'open',
                                    type: 'url',
                                    url: `https://vk.com/app${window.vk_settings.vk_app_id}`
                                }
                            });
                        }}
                    >
                        В историю
                    </Cell>
                    <Cell
                        before={<Icon24ShareOutline />}
                        onClick={() => {
                            bridge.send('VKWebAppShowWallPostBox', {
                                message: '#нампопути',
                                attachments: `https://vk.com/app${window.vk_settings.vk_app_id}`,
                            });
                        }}
                    >
                        На стену
                    </Cell>
                </List>
            </Div>
        </ModalPage>
    );
};

export default Share;
