import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalPage, List, Cell } from '@vkontakte/vkui';
import statuses from '../../../data/statuses.json';

import { closeModal } from '../../../store/navigation/actions';
import { setStatus } from '../../../store/calculator/actions';

import ModalPageHeaderWithButtons from '../ModalPageHeaderWithButtons';

const SelectStatus = ({ id }) => {
    const dispatch = useDispatch();
    const currentStatus = useSelector((store) => store.calculator.status);

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={
                <ModalPageHeaderWithButtons>
                    Статус
                </ModalPageHeaderWithButtons>
            }
        >
            <List>
                {statuses.map((status) => (
                    <Cell
                        key={status.text}
                        onClick={() => {
                            dispatch(setStatus(status));
                            dispatch(closeModal());
                        }}
                        indicator={
                            currentStatus !== null && currentStatus.text === status.text && <div className='icon-check' />
                        }
                    >
                        {status.text}
                    </Cell>
                ))}
            </List>
        </ModalPage>
    );
};

export default SelectStatus;
