import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Panel, PanelHeader } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import GiveawayStepList from "../../components/GiveawayStepList";
import GiveawayWinnerList from "../../components/GiveawayWinnerList";
import './index.scss';

import giveawayIconCard from "../../assets/img/icon-card-b.svg";
import giveawayIconPoint from "../../assets/img/icon-point-b.svg";
import giveawayIconShare from "../../assets/img/icon-share.svg";

import GiveawayAvatar01 from "../../assets/img/user-avatar.jpg";
import GiveawayAvatar02 from "../../assets/img/user-avatar-02.jpg";

import { openStory } from '../../store/navigation/actions';
import {request} from "../../networking/api";
import fireEvent from '../../utils/fireEvent';
import GA from "react-ga";

const Giveaway = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const [ winners, setWinners ] = useState([]);

    const giveawayStep = [
        {icon: giveawayIconCard, text: 'Авторизуйтесь с картой лояльности <b>«Нам по пути»</b>'},
        {icon: giveawayIconPoint, text: 'Выберите интересный для вас маршрут'},
        {icon: giveawayIconShare, text: 'Поделитесь им у себя на&nbsp;стене «ВКонтакте» с&nbsp;сохранением хештега #НамПоПути'}
    ];

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        request('user.getWinner', {})
            .then(({ info }) => setWinners(info))
            .catch(console.log)
    }, []);

    const clickGiveaway = () => {
        GA.event({
            category: 'giveaway',
            action: 'click-win',
        });

        dispatch(openStory(`routes${!user.gpnPhone ? '-login' : ''}`));
    };

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('home'))}
                    title='Розыгрыш'
                />
            </PanelHeader>
            <div className="block-wrap giveaway">
                <div className="giveaway__title">
                    <b>Дарим топливо для&nbsp;впечатляющих путешествий!</b>
                    <span>Делитесь своими маршрутами и получите шанс выиграть полный бак</span>
                </div>
                <GiveawayStepList item={giveawayStep} />
                <div className="giveaway__info">
                    <p>Откройте, пожалуйста, свой профиль, чтобы мы увидели ваш репост!</p>
                    <p>Два победителя будут определены случайным образом. Розыгрыш пройдет 15 и 22 марта</p>
                </div>
                <div className="giveaway__buttons">
                    <div
                        className="btn btn-empty"
                        onClick={() => fireEvent("https://drive.google.com/file/d/17J_HX_ZKjHDy0eUPREVAs47obNk6OenI/view")}
                    >
                        Подробные условия акции
                    </div>
                    <div
                        className="btn btn-primary"
                        onClick={clickGiveaway}
                    >
                        выиграть топливо
                    </div>
                </div>
                {winners.length > 0 && (
                    <GiveawayWinnerList item={winners} />
                )}
            </div>
        </Panel>
    );
};

export default Giveaway;
