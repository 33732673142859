import React from 'react';
import './index.scss';

const GiveawayWinnerList = ({ item }) => {
    return (
        <div className="giveaway__winners">
            <div className="giveaway__winners--title">Победители</div>
            <div className="giveaway__winners--list">
                {item.map((giveaway) => (
                    <div className="giveaway__winners--item" key={giveaway.id}>
                        <div className="giveaway__winners--avatar" style={{ backgroundImage: `url(${giveaway.photo_200})` }} />
                        <div className="giveaway__winners--info">
                            <b>{giveaway.fio}</b>
                            {!!giveaway.city && (
                                <span>г. {giveaway.city}</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GiveawayWinnerList;
