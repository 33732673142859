import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalPage, Div } from '@vkontakte/vkui';

import { closeModal } from '../../../store/navigation/actions';

import ModalPageHeaderWithButtons from '../ModalPageHeaderWithButtons';

const CalculatorInfo = ({ id }) => {
    const dispatch = useDispatch();

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={
                <ModalPageHeaderWithButtons>
                    Информация
                </ModalPageHeaderWithButtons>
            }
        >
            <Div>
                Акция «Двойные бонусы» проводится с 09.11.2020 г. по 31.01.2021 г. (вкл.) на АЗС в сети «Газпромнефть» на территории РФ. Организатор акции
                ООО «Газпромнефть-Центр». Для получения двойных бонусов необходимо в период акции совершить покупку топлива от 30 литров и более, оплатить наличными или банковской картой с применением карты «Нам по пути» (далее – ПЛ) и получить в 2 раза больше бонусов по сравнению с обычным начислением в зависимости от статуса карты ПЛ (согласно Приложения 2 к Правилам ПЛ). В акции участвуют: АИ-92, АИ-95, АИ-98, 92 «ОПТИ», 95 «ОПТИ», G-Drive 95, G-Drive 98, G-Drive 100, ДТ, «Дизель ОПТИ», G-Drive Diesel, СУГ. Подробнее об организаторе, правилах акции, участвующих АЗС, ограничениях и исключениях - на сайте www.gpnbonus.ru. и по телефону 8 800 700 51 51 (звонок по России бесплатный).
            </Div>
        </ModalPage>
    );
};

export default CalculatorInfo;
