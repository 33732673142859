import * as types from './actionTypes';

export const loadRoutes = (routes = []) => {
    return {
        type: types.ROUTES_LOAD,
        routes,
    };
};

export const selectRoute = (id = '', story_from = '') => {
    return {
        type: types.ROUTES_SELECT_ROUTE,
        id,
        story_from,
    };
};

export const selectCategory = (id = '') => {
    return {
        type: types.ROUTES_SELECT_CATEGORY,
        id,
    };
};

export const selectDay = (day) => {
    return {
        type: types.ROUTES_SELECT_DAY,
        day,
    };
};

export const selectPoint = (point = {}) => {
    return {
        type: types.ROUTES_SELECT_POINT,
        point,
    };
};