import React from 'react';
import './index.scss';

const PanelTitle = ({ title, beforeIcon, onClick }) => {
    return (
        <div className="Panel-title" onClick={onClick}>
            {beforeIcon ? <div className='Panel-title__icon'>{beforeIcon}</div> : ''}
            <span>{title}</span>
        </div>
    );
};

export default PanelTitle;
