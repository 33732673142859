import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { request } from '../../../networking/api';
import {ModalPage, List, Cell, Input} from '@vkontakte/vkui';

import { closeModal } from '../../../store/navigation/actions';

import ModalPageHeaderWithButtons from '../ModalPageHeaderWithButtons';

import { userUpdate } from '../../../store/user/actions';

const SelectCity = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const cities = useSelector((store) => store.routes.cities);

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={
                <ModalPageHeaderWithButtons>
                    Город
                </ModalPageHeaderWithButtons>
            }
        >
            <List>
                {cities.map((city) => (
                    <Cell
                        key={city}
                        onClick={() => {
                            request('user.editPhone', {
                                phone: user.gpnPhone,
                                city,
                            })
                                .then(() => {
                                    dispatch(userUpdate('gpnCity', city));
                                    dispatch(closeModal());
                                })
                        }}
                    >
                        {city}
                    </Cell>
                ))}
            </List>
        </ModalPage>
    );
};

export default SelectCity;
