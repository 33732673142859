import "core-js/features/map";
import "core-js/features/set";

import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import "@vkontakte/vkui/dist/vkui.css";

import { VKGetProfile, request } from "./networking/api";
import { userUpdate } from "./store/user/actions";

import App from "./containers";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store";
import * as GA from "react-ga";

export const store = createStore(rootReducer);

GA.initialize("UA-185334757-6", {
  gaOptions: {
    cookieDomain: "none",
    cookieFlags: "SameSite=None; Secure",
  },
});
GA.set("checkProtocolTask", null);

bridge.send("VKWebAppInit");

window.vk_settings = window.location.search
  .slice(1)
  .split("&")
  .reduce((a, x) => {
    const data = x.split("=");
    return {
      ...a,
      [data[0]]: data[1],
    };
  }, {});

bridge.subscribe((event) => {
  if (event.detail.type === "VKWebAppAllowNotificationsResult") {
    store.dispatch(userUpdate("notify", true));
  }

  if (event.detail.type === "VKWebAppDenyNotificationsResult") {
    store.dispatch(userUpdate("notify", false));
  }
});

(async () => {
  const profileData = await VKGetProfile();
  const gpnData = await request("user.getInfo", {})
    .then(({ info }) => info)
    .catch(() => ({}));

  if (profileData !== null) {
    store.dispatch({
      type: "@user/load",
      data: {
        ...profileData,
        gpnCity: gpnData.city,
        gpnPhone: gpnData.phone,
        warnShown: false,
        notify: window.vk_settings["vk_are_notifications_enabled"] !== "0",
      },
    });

    // ReactDOM.render(
    //     <Provider store={store}>
    //         <App />
    //     </Provider>,
    //     document.getElementById('root')
    // );
  }
})();

//TODO: fix user fetch
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
