import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, Input, SelectMimicry } from '@vkontakte/vkui';
import './index.scss';

import { openModal, openStory } from '../../store/navigation/actions';
import { setLitres, setSum, exec, reset } from '../../store/calculator/actions';
import PanelTitle from "../../components/PanelTitle";
import { wordPad } from "../../utils/_functions";
import GA from "react-ga";

const Calculator = ({ id }) => {
    const dispatch = useDispatch();
    const litres = useSelector((store) => store.calculator.litres);
    const sum = useSelector((store) => store.calculator.sum);
    const oil = useSelector((store) => store.calculator.oil);
    const status = useSelector((store) => store.calculator.status);
    const result = useSelector((store) => store.calculator.result);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        if (litres > 0 && sum > 0 && oil && oil.value > 0 && status && status.value > 0) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [litres, oil, status, sum]);

    const checkClose = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur()
        }
    };

    const calcIt = () => {
        GA.event({
            category: 'calculator',
            action: 'click-calculate',
        });

        dispatch(exec());
    };

    const parseValue = (val, def) => {
        if (
            val.match(/^(?!0(\.0+)?$)?(0|[1-9]\d*)?$/) === null ||
            parseFloat(val) < 0 ||
            parseFloat(val) > 9999999999)
        {
            if (val === '') {
                return '';
            } else {
                if (def === null || def === '') {
                    return '';
                } else if (litres === '-') {
                    return '-';
                } else {
                    return Math.floor(def);
                }
            }
        } else {
            if (val === '' || val === '-') {
                return val;
            } else {
                return Math.floor(val);
            }
        }
    };

    const changeLitres = (e) => {
        const litresNum = parseValue(e.currentTarget.value, litres);
        dispatch(setLitres(litresNum))
    };

    const changeSum = (e) => {
        const sumNum = parseValue(e.currentTarget.value, sum);
        dispatch(setSum(sumNum));
    };

    return (
        <Panel id={id}>
            <PanelHeader>
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('home'))}
                    title='Калькулятор'
                />
            </PanelHeader>
            <div className="block-wrap calculator">
                <div className="calculator__info">
                    <div className="form-select">
                        <SelectMimicry
                            placeholder="Ваш статус участника программы"
                            onClick={() => dispatch(openModal('select-status'))}
                        >
                            {status !== null && status.text}
                        </SelectMimicry>
                    </div>
                    <div className="form-input">
                        <Input
                            placeholder="Количество литров"
                            value={litres}
                            onChange={changeLitres}
                            onKeyUp={checkClose}
                            type="number"
                            pattern="[0-9]*"
                            inputmode="numeric"
                        />
                    </div>
                    <div className="form-select">
                        <SelectMimicry
                            placeholder="Топливо"
                            onClick={() => dispatch(openModal('select-oil'))}
                        >
                            {oil !== null && oil.text}
                        </SelectMimicry>
                    </div>
                    <div className="form-input">
                        <Input
                            top="Потрачено на покупку товаров"
                            placeholder="Потрачено на покупку товаров"
                            value={sum}
                            onChange={changeSum}
                            onKeyUp={checkClose}
                            type="number"
                            pattern="[0-9]*"
                            inputmode="numeric"
                        />
                    </div>
                </div>
                {result !== null && (
                    <div className="calculator__result">
                        <div className="calculator__result--title">Итого</div>
                        <div className="calculator__result--bonus">
                            {result.bonuses} {wordPad(result.bonuses, ' бонус', ' бонуса', ' бонусов')}*
                        </div>
                        <div className="calculator__result--desc">1 бонус = 1 рубль</div>
                        <div className="calculator__result--info">
                            {/*<p>*/}
                            {/*    <span>*До 31.01.21 все бонусы за заправку удваиваются</span>*/}
                            {/*    <div className='icon-info' onClick={() => dispatch(openModal('calc-info'))} />*/}
                            {/*</p>*/}
                            <p>
                                <span>*Расчет бонусов является примерным&nbsp;</span>
                                <div className='icon-info' onClick={() => dispatch(openModal('calc-info-2'))} />
                            </p>
                        </div>
                    </div>
                )}
                <div className="calculator__buttons">
                    <div className="btn btn-empty" onClick={() => dispatch(reset())}>сбросить данные</div>
                    <div className={`btn btn-primary${isButtonDisabled ? ' btn-disabled' : ''}`} onClick={calcIt}>рассчитать <div className='btn-icon arrow-right' /></div>
                </div>
            </div>
        </Panel>
    );
};

export default Calculator;
