import React from 'react';
import './index.scss';

const GiveawayStepList = ({ item }) => {
    const renderList = () => {
        return item.map((giveaway, i) => (
            <div className="giveaway__step--item" key={i}>
                <div className="giveaway__step--icon" style={{ backgroundImage: `url(${giveaway.icon})` }} />
                <div className="giveaway__step--text" dangerouslySetInnerHTML={{__html: giveaway.text}} />
            </div>
        ));
    };
    return (
        <div className="giveaway__step">{renderList()}</div>
    );
};

export default GiveawayStepList;
