import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Root, View, Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import GA from 'react-ga';

import { openStory } from '../store/navigation/actions';

import Intro from './Intro';
import Welcome from './Welcome';
import Home from './Home';
import Calculator from './Calculator';
import Modals from './Modals';

import './index.scss';
import Routes from "./Routes";
import RoutesLogin from "./RoutesLogin";
import Geolocation from "./Geolocation";
import RoutesFavorite from "./RoutesFavorite";
import Bonuses from "./Bonuses";
import Giveaway from "./Giveaway";
import RoutesCat from "./RoutesCat";
import RoutesInfo from "./RoutesInfo";
import RoutesMap from "./RoutesMap";
import Profile from "./Profile";
import ProfileChangePhone from "./ProfileChangePhone";
import ProfileNoCard from "./ProfileNoCard";
import {isMobileAndroid} from "mini-juice/vk";

const App = () => {
	const dispatch = useDispatch();
	const activeView = useSelector((store) => store.navigation.activeView);
	const activeStory = useSelector((store) => store.navigation.activeStory);
	const user = useSelector((store) => store.user.data);

	useEffect(() => {
		// Вырубить кнопку назад
		if (isMobileAndroid()) {
			for (let i = 0; i < 100; i++) {
				window.history.pushState({panel: `main${i}`}, `main${i}`);
			}
			window.history.pushState({noBackExitsApp: true}, '');
			window.addEventListener('popstate', function (event) {
				window.history.pushState({noBackExitsApp: true}, '')
			});
		}

		GA.event({
			category: 'app',
			action: 'init',
		});
	}, []);

	const switchActiveStory = (story) => {
		dispatch(openStory(story));
	};

	const tabbar = (
		<Tabbar>
			<TabbarItem
				text="Главная"
				selected={activeStory === 'home'}
				onClick={() => switchActiveStory('home')}
			>
				<div className='tabbar-icon tabbar-icon__home' />
			</TabbarItem>
			<TabbarItem
				text="Маршруты"
				selected={activeStory.includes('routes')}
				onClick={() => switchActiveStory(`routes${!user.gpnPhone ? '-login' : ''}`)}
			>
				<div className='tabbar-icon tabbar-icon__point' />
			</TabbarItem>
			<TabbarItem
				text="Бонусы"
				selected={activeStory === 'bonuses'}
				onClick={() => switchActiveStory('bonuses')}
			>
				<div className='tabbar-icon tabbar-icon__bonus' />
			</TabbarItem>
			<TabbarItem
				text="Калькулятор"
				selected={activeStory === 'calculator'}
				onClick={() => switchActiveStory('calculator')}
			>
				<div className='tabbar-icon tabbar-icon__calc' />
			</TabbarItem>
			<TabbarItem
				text="Розыгрыш"
				selected={activeStory === 'giveaway'}
				onClick={() => switchActiveStory('giveaway')}
			>
				<div className='tabbar-icon tabbar-icon__gifts' />
			</TabbarItem>
		</Tabbar>
	);

	return (
		<Root
			activeView={activeView}
			modal={
				<Modals />
			}
		>
			<View id="intro" activePanel="intro">
				<Intro id="intro" />
			</View>
			<View id="welcome" activePanel="welcome">
				<Welcome id="welcome" />
			</View>
			<Epic
				id="main"
				activeStory={activeStory}
				tabbar={tabbar}
			>
				<Home id="home" />
				<Routes id='routes' />
				<Geolocation id='geolocation' />
				<RoutesLogin id='routes-login' />
				<RoutesFavorite id='routes-favorite' />
				<RoutesCat id='routes-cat' />
				<RoutesInfo id='routes-info' />
				<RoutesMap id='routes-map' />
				<Bonuses id='bonuses' />
				<Calculator id="calculator" />
				<Giveaway id='giveaway' />
				<Profile id='profile' />
				<ProfileNoCard id='profile-no-card' />
				<ProfileChangePhone id='profile-change-phone' />
			</Epic>
		</Root>
	);
};

export default App;
