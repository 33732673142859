import * as types from './actionTypes';
import categories from '../../data/categories.json';
import citiesToIgnore from '../../data/cities-to-igone.json';

const initialState = {
    loaded: true,
    routes: [],
    categories: [],
    selectedCategory: null,
    selectedRoute: null,
    selectedDay: 0,
    selectedPoint: null,
    cities: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ROUTES_LOAD: {
            const { routes } = action;

            // parse categories
            // const categories = [];
            // routes.forEach((route) => {
            //     for (let i = 0; i < route.categories.length; i++) {
            //         if (!categories.includes(route.categories[i])) {
            //             categories.push(route.categories[i]);
            //         }
            //     }
            // });

            let newCities = [];
            routes.forEach(route => {
                if (!citiesToIgnore.includes(route.start_city_name) && !newCities.includes(route.start_city_name)) {
                    newCities.push(route.start_city_name);
                }
            });

            newCities.sort();

            return {
                ...state,
                loaded: true,
                routes,
                categories,
                selectedRoute: routes[0],
                cities: [...newCities],
            };
        }

        case types.ROUTES_SELECT_ROUTE: {
            const { id, story_from } = action;
            return {
                ...state,
                selectedDay: 0,
                selectedPoint: null,
                selectedRoute: {
                    ...state.routes.find((x) => x.id === id),
                    story_from,
                },
            };
        }

        case types.ROUTES_SELECT_CATEGORY: {
            const { id } = action;
            return {
                ...state,
                selectedCategory: id,
            };
        }

        case types.ROUTES_ADD_TO_FAVS: {
            const { id } = action;
            return {
                ...state,
                routes: state.routes.map((x) => {
                    if (x.id === id) {
                        return {
                            ...x,
                            is_favourite: 1,
                        };
                    }

                    return x;
                }),
                selectedRoute: state.selectedRoute !== null && state.selectedRoute.id === id ? {
                    ...state.selectedRoute,
                    is_favourite: 1,
                } : state.selectedRoute,
            }
        }

        case types.ROUTES_REMOVE_FROM_FAVS: {
            const { id } = action;
            return {
                ...state,
                routes: state.routes.map((x) => {
                    if (x.id === id) {
                        return {
                            ...x,
                            is_favourite: 0,
                        };
                    }

                    return x;
                }),
                selectedRoute: state.selectedRoute !== null && state.selectedRoute.id === id ? {
                    ...state.selectedRoute,
                    is_favourite: 0,
                } : state.selectedRoute,
            }
        }

        case types.ROUTES_SELECT_DAY: {
            const { day } = action;
            return {
                ...state,
                selectedDay: day,
            };
        }

        case types.ROUTES_SELECT_POINT: {
            const { point } = action;
            return {
                ...state,
                selectedPoint: point,
            };
        }

        default: return state;
    }
};
