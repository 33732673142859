import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../networking/api';
import { ModalCard } from '@vkontakte/vkui';

import { closeModal, openStory } from '../../../store/navigation/actions';
import { userUpdate } from '../../../store/user/actions';

const CityAsk = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);

    return (
        <ModalCard
            id={id}
            onClose={() => dispatch(closeModal())}
            actions={[{
                action: () => dispatch(closeModal()),
            }]}
        >
            <div className="modal-title">Ваш город Москва?</div>
            <div className="geolocation-near__btn">
                <div
                    className="btn btn-primary"
                    onClick={() => {
                        request('user.editPhone', {
                            phone: user.gpnPhone,
                            city: 'Москва',
                        })
                            .then(() => {
                                dispatch(userUpdate('gpnCity', 'Москва'));
                                dispatch(closeModal());
                            })
                    }}
                >
                    да
                </div>
                <div
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(openStory('geolocation'));
                        dispatch(closeModal());
                    }}
                >
                    нет, другой
                </div>
            </div>
        </ModalCard>
    );
};

export default CityAsk;
