import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { request } from '../../networking/api';
import {Input, Panel, PanelHeader, FormLayout} from "@vkontakte/vkui";
import './index.scss';

import { openStory } from '../../store/navigation/actions';
import { userUpdate } from '../../store/user/actions';
import fireEvent from "../../utils/fireEvent";
import GA from "react-ga";
import bridge from "@vkontakte/vk-bridge";
import {isDesktopWeb} from "mini-juice/vk";

const RoutesLogin = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const [ phone, setPhone ] = useState('');
    const [ isPhoneFillAsked, setIsPhoneFillAsked ] = useState(false);
    const [ isPhoneRight, setIsPhoneRight ] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        if (phone.length >= 12) {
            setIsPhoneRight(true);
        } else {
            setIsPhoneRight(false);
        }
    }, [phone]);

    const clickContinue = () => {
        GA.event({
            category: 'routes',
            action: 'click-continue',
        });

        if (phone.length >= 12) {
            request('user.editPhone', {
                phone,
                city: user.gpnCity || '',
            })
                .then(() => {
                    dispatch(openStory('routes'));
                    dispatch(userUpdate('gpnPhone', phone));
                    dispatch(userUpdate('gpnCity', user.gpnCity || ''));
                });
        }
    };

    const getCard = () => {
        GA.event({
            category: 'routes',
            action: 'click-get-card',
        });

        if (isDesktopWeb()) {
            fireEvent('https://www.gpnbonus.ru/on_the_way/how_to_start/');
        } else {
            fireEvent('https://m.gpnbonus.ru/how_to_start/');
        }
    };

    const clickPhoneInput = () => {
        if (!isPhoneFillAsked) {
            setIsPhoneFillAsked(true);
            bridge.send('VKWebAppGetPhoneNumber')
                .then((response) => {
                    if (response.phone_number) {
                        setPhone(`+${response.phone_number}`);
                    }
                })
                .catch(() => {
                    setPhone('+7');
                })
        } else {
            if (phone.length <= 2) {
                setPhone('+7');
            }
        }
    };

    const changePhoneInput = (e) => {
        const value = e.currentTarget.value;
        const newValue = value.replaceAll('.', '');
        if (!(!Number(newValue) || (newValue.length > 2 && !newValue.startsWith('+7')))) {
            setPhone(newValue)
        } else {
            let numb = newValue.match(/\d/g);
            if (numb === null) {
                numb = 7;
            } else {
                numb = numb.join("");
            }
            setPhone(`+${numb}`);
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <div className="logo" />
            </PanelHeader>
            <div className='block-wrap'>
                <div className="routes-login__ask">Вы участник программы лояльности <b>«Нам по пути»?</b></div>
                <div className="routes-login__login">
                        <FormLayout className='form-input'>
                            <Input
                                top="Введите номер телефона, привязанный к карте лояльности"
                                type="tel"
                                value={phone}
                                maxLength={12}
                                onClick={clickPhoneInput}
                                onChange={changePhoneInput}
                            />
                        </FormLayout>
                    <div
                        className={`btn btn-primary${!isPhoneRight ? ' btn-disabled' : ''}`}
                        onClick={clickContinue}
                    >
                        продолжить <div className='btn-icon arrow-right' />
                    </div>
                </div>
                <div className="routes-login__advantage">
                    <span>У вас нет карты ?</span>
                    <ul>
                        <li>Выпустите бесплатную виртуальную карту <b className='bold-blue'>«Нам по пути»</b> в мобильном приложении за пару минут</li>
                        <li>Получите <b className="bold-blue">100 приветственных бонусов</b> для выгодного старта вашей поездки</li>
                    </ul>
                </div>
                <div className="routes-login__btn">
                    <div
                        className="btn btn-primary"
                        onClick={getCard}
                    >
                        выпустить карту
                    </div>
                    <div
                        className="btn btn-primary"
                        onClick={() => {
                            dispatch(openStory('routes'));
                        }}
                    >
                        далее <div className='btn-icon arrow-right' />
                    </div>
                </div>
            </div>
        </Panel>
    );
};

export default RoutesLogin;
