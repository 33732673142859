import React from 'react';
import './index.scss';

const HomeCard = ({ title, image, onClick }) => {
    return (
        <div className='block-wrap'>
            <div className="home-card" onClick={onClick}  style={{ backgroundImage: `url(${image})` }}>
                <div className="home-card__title">{title}</div>
            </div>
        </div>
    );
};

export default HomeCard;
