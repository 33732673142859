import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelHeader, Gallery } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import './index.scss';

import card from '../../assets/img/card-image.png';
import { openModal, openStory } from '../../store/navigation/actions';
import fireEvent from "../../utils/fireEvent";
import GA from "react-ga";
import {isDesktopWeb} from "mini-juice/vk";

const Bonuses = ({ id }) => {
    const dispatch = useDispatch();
    const isFromRoute = useSelector((store) => store.navigation.is_from_route);

    const [galleryRef, setGalleryRef] = useState(null);

    const [ slide, setSlide ] = useState(0);
    const [ bulletsOn, setBulletsOn ] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setBulletsOn(true);
        }, 500)
    }, []);

    useEffect(() => {
        if (bulletsOn) {
            const bullets = [...document.body.getElementsByClassName('Gallery__bullet')];

            if (bullets.length > 0) {
                bullets.forEach((bullet, num) => {
                    bullet.addEventListener('click', () => setSlide(num));
                });

                return () => {
                    bullets.map((bullet, num) => {
                        bullet.removeEventListener('click', () => setSlide(num));
                    });
                };
            }
        }
    }, [bulletsOn]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [slide]);

    const nextSlide = () => {
        setSlide((p) => p === 5 ? 0 : p + 1);
    };

    const getCardClick = () => {
        GA.event({
            category: 'bonuses',
            action: `click-select-slide-${slide + 1}`,
        });

        if (isDesktopWeb()) {
            fireEvent('https://www.gpnbonus.ru/on_the_way/how_to_start/');
        } else {
            fireEvent('https://m.gpnbonus.ru/how_to_start/');
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory(isFromRoute ? 'routes-info' : 'home'))}
                    title='Бонусы'
                />
            </PanelHeader>
            <div className="bonuses">
                <Gallery bullets='light' slideIndex={slide} onChange={setSlide}>
                    <div className="block-wrap bonuses-card bonuses__wrap">
                        <div className="bonuses-card__title">ПРОГРАММА ЛОЯЛЬНОСТИ «Нам по пути»</div>
                        <img src={card} alt='' />
                        <div className="bonuses-card__subtitle"> С обновленной программой лояльности «Нам по пути» путешествовать стало еще выгоднее!</div>
                        <p>Получайте больше бонусов за покупку топлива на АЗС «Газпромнефть» и легко рассчитывайте сумму бонусных накоплений:</p>
                        <table cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>ОБЪЕМ<span>за месяц</span></th>
                                    <th>СТАТУС<span> </span></th>
                                    <th>БОНУСЫ<span>за литр</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>до 99.99 л</td>
                                    <td>серебряный</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>от 100 до<br/>299.99 л</td>
                                    <td>золотой</td>
                                    <td>1.5</td>
                                </tr>
                                <tr>
                                    <td>от 300 л</td>
                                    <td>платиновый</td>
                                    <td>2</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Оплачивайте бонусами топливо и другие покупки на АЗС «Газпромнефть».<br />1 бонус = 1 рубль.</p>
                        <p>Присоединяйтесь к программе лояльности и&nbsp;сделайте свое путешествие еще приятнее!</p>
                    </div>
                    <div className="block-wrap bonuses-plus bonuses__wrap">
                        <div className="bonuses-plus__num">
                            <span>+100</span>
                        </div>
                        <div className="bonuses-plus__title">Приветственных бонусов</div>
                        <div className="bonuses-plus__hr" />
                        <div className="bonuses-plus__text">
                            <p>Присоединяйтесь к программе лояльности и получите 100 приветственных бонусов на карту лояльности при первой заправке</p>
                        </div>
                    </div>
                    <div className="block-wrap bonuses-plus bonuses__wrap">
                        <div className="bonuses-plus__num">
                            <span>+50</span>
                            <div className="bonuses-plus__info" onClick={() => dispatch(openModal('bonus-50'))} />
                        </div>
                        <div className="bonuses-plus__title">Бонусов<br/> за лояльность</div>
                        <div className="bonuses-plus__hr" />
                        <div className="bonuses-plus__text">
                            <p>Вошли во вкус автопушествий?<br/> У нас хорошая новость: мы дарим 50 бонусов каждые 3 месяца за регулярную покупку топлива!</p>
                        </div>
                    </div>
                    <div className="block-wrap bonuses-plus bonuses__wrap">
                        <div className="bonuses-plus__num">
                            <span>+100</span>
                            <div className="bonuses-plus__info" onClick={() => dispatch(openModal('bonus-100'))} />
                        </div>
                        <div className="bonuses-plus__title">Бонусов<br/> за друга</div>
                        <div className="bonuses-plus__hr" />
                        <div className="bonuses-plus__text">
                            <p>Расскажите друзьям о нашем приложении — пусть они тоже найдут для себя интересный маршрут!</p>
                            <p>Поделитесь с ними ссылкой на программу лояльности и получите по 100 бонусов за каждого зарегистрировавшегося друга</p>
                        </div>
                    </div>
                    <div className="block-wrap bonuses-plus bonuses__wrap">
                        <div className="bonuses-plus__num">
                            <div className="icon-hand" />
                            <div className="bonuses-plus__info" onClick={() => dispatch(openModal('bonus-share'))} />
                        </div>
                        <div className="bonuses-plus__title">Делитесь<br/> бонусами!</div>
                        <div className="bonuses-plus__hr" />
                        <div className="bonuses-plus__text">
                            <p>Если остаётесь дома, сделайте поездки близких людей выгодными - переведите им бонусы на карту лояльности <span className="bold-blue">«Нам по пути»</span> легко и бесплатно</p>
                        </div>
                    </div>
                    <div className="block-wrap bonuses-plus bonuses__wrap">
                        <div className="bonuses-plus__num">
                            <div className='bonuses-plus__card-icon'/>
                        </div>
                        <div className="bonuses-plus__title">Виртуальная карта</div>
                        <div className="bonuses-plus__hr" />
                        <div className="bonuses-plus__text">
                            <p>С виртуальной картой лояльности «Нам по пути» от АЗС «Газпромнефть» вы сможете оплатить заправку в мобильном приложении быстро и бесконтактно</p>
                        </div>
                    </div>
                </Gallery>
                <div className="bonuses__bottom">
                    <div className="btn-round" onClick={nextSlide} />
                    <div className="btn btn-outline-blue" onClick={getCardClick}>оформить карту</div>
                </div>
            </div>
        </Panel>
    );
};

export default Bonuses;
