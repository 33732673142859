import React, {useEffect, useState} from 'react';
import {Panel, PanelHeader, SelectMimicry} from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import './index.scss';
import {openModal, openStory} from "../../store/navigation/actions";
import {useDispatch, useSelector} from "react-redux";
import GA from "react-ga";

const Geolocation = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const [ shown, setShown ] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    const selectCity = () => {
        if (shown) {
            GA.event({
                category: 'geo',
                action: 'click-select-no-city',
            });
        } else {
            GA.event({
                category: 'geo',
                action: 'click-select-city',
            });
        }

        if (!!user.gpnCity) {
            dispatch(openStory('routes'));
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('home'))}
                    title='Геолокация'
                />
            </PanelHeader>
            <div className="block-wrap geolocation">
                {shown && (
                    <p style={{ margin: '0 25px', padding: 0, textAlign: 'center' }}>
                        К сожалению, у нас пока нет маршрутов из вашего города.<br/>
                        Возможно, ваш идеальный маршрут начинается в одном из этих городов:
                    </p>
                )}
                <div className="select" style={shown ? { marginTop: 25 } : { marginTop: '25%' }}>
                    <SelectMimicry
                        placeholder={user.gpnCity || 'Выберите город'}
                        onClick={() => dispatch(openModal('select-city'))}
                    />
                </div>
                <div
                    className="btn btn-primary"
                    onClick={selectCity}
                    style={{ opacity: !!user.gpnCity ? 1 : .25 }}
                >
                    выбрать
                </div>
                {!shown && (
                    <div className="btn btn-empty" style={{ marginTop: 10 }} onClick={() => setShown(true)}>
                        моего города нет в списке
                    </div>
                )}
            </div>
        </Panel>
    );
};

export default Geolocation;
