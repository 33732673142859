import React from 'react';
import he from 'he';
import './index.scss';

const HomeUser = ({ avatar, name }) => {
    const s = '&#39;';

    return (
        <div className='block-wrap'>
            <div className="home__user">
                <div className="home__user--avatar" style={{ backgroundImage: `url(${avatar})` }} />
                <div className="home__user--info">
                    <b>Привет, {he.decode(name)}!</b>
                    <span>Впечатляющие путешествия ждут вас!</span>
                </div>
            </div>
        </div>
    );
};

export default HomeUser;
