import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalPage } from '@vkontakte/vkui';
import { closeModal } from '../../../store/navigation/actions';


const MapInfo = ({ id }) => {
    const dispatch = useDispatch();
    const selectedPoint = useSelector((store) => store.routes.selectedPoint);

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={null}
        >

            <div className="map-info">
                <div className="map-info__title">
                    <span>{selectedPoint.name}</span>
                </div>
                <div className="map-info__info" dangerouslySetInnerHTML={{ __html: selectedPoint.description }} />
            </div>
        </ModalPage>
    );
};

export default MapInfo;
