import { combineReducers } from 'redux';

import navigation from './navigation';
import calculator from './calculator';
import user from './user';
import routes from './routes';

export default combineReducers({
    navigation,
    calculator,
    user,
    routes,
});
