import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { YMaps, Map as YMap, Placemark, GeoObject } from 'react-yandex-maps';
import { Panel, PanelHeader } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import './index.scss';

import Map from '../../assets/img/map.jpg';
import buildRoute from '../../utils/buildRoute';

import { openStory, openModal } from "../../store/navigation/actions";
import { selectPoint } from '../../store/routes/actions';

import HereIcon from '../../assets/here.svg';
import GA from "react-ga";

const RoutesMap = ({ id }) => {
    const dispatch = useDispatch();
    const [ map, setMap ] = useState(null);
    const selectedRoute = useSelector((store) => store.routes.selectedRoute);
    const selectedDay = useSelector((store) => store.routes.selectedDay);
    const link = buildRoute(selectedRoute.days[selectedDay].events);

    const points = selectedRoute.days[selectedDay].events;

    const renderPoints = () => {
        return points.map((point) => {
            return (
                <Placemark
                    key={point.id}
                    geometry={[
                        point.latitude,
                        point.longitude,
                    ]}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: HereIcon,
                        iconImageSize: [40, 54],
                        iconImageOffset: [0, -52],
                    }}
                    onClick={() => {
                        dispatch(selectPoint(point));
                        dispatch(openModal('map-info'));
                    }}
                />
            );
        })
    };

    const openMap = () => {
        GA.event({
            category: 'routes',
            action: 'open-yandex-map',
        });
    };

    return (
        <Panel id={id} style={{ position: 'fixed' }}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('routes-info'))}
                    title='Карта'
                />
            </PanelHeader>
            <YMaps style={{ position: 'fixed' }}>
                <YMap
                    defaultState={{
                        center: [
                            points[0].latitude,
                            points[0].longitude
                        ],
                        zoom: 12
                    }}
                    width={window.innerWidth}
                    height={window.innerHeight - 74}
                    instanceRef={(map) => {
                        if (map !== null) {
                            const lat = points.map((x) => Number(x.latitude)).reduce((a, x) => a + x, 0) / points.length;
                            const long = points.map((x) => Number(x.longitude)).reduce((a, x) => a + x, 0) / points.length;
                            map.setCenter([lat, long], 10);
                        }
                    }}
                >
                    {points && points.length > 0 && renderPoints()}
                </YMap>
            </YMaps>
            <div
                className='routes-map'
            >
                <div className="routes-map__buttons">
                    <div
                        className="btn btn-primary"
                        onClick={() => dispatch(openModal('share'))}
                    >
                        поделиться <div className='btn-icon arrow-right' />
                    </div>
                    <a
                        className="btn btn-primary"
                        href={link}
                        target="_blank"
                        onClick={openMap}
                    >
                        открыть в Яндекс.Картах
                    </a>
                </div>
            </div>
        </Panel>
    );
};

export default RoutesMap;
