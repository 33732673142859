import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import {Panel, Gallery, Checkbox} from '@vkontakte/vkui';
import slides from '../../data/slides.json';
import './index.scss';

import { openView } from '../../store/navigation/actions';

import welcome01 from '../../assets/img/welcome-01.jpg';
import welcome02 from '../../assets/img/welcome-02.jpg';
import welcome03 from '../../assets/img/welcome-03.jpg';
import bridge from "@vkontakte/vk-bridge";
import {bridgeSetStatusBar} from "mini-juice/vk";

const Welcome = ({ id }) => {
    const dispatch = useDispatch();
    const [ slide, setSlide ] = useState(0);
    const [ isCheck1, setIsCheck1 ] = useState(false);
    const [ isCheck2, setIsCheck2 ] = useState(false);

    // useEffect(() => {
    //     bridgeSetStatusBar({
    //         status_bar_style: 'light',
    //         action_bar_color: '#00a4e4',
    //         navigation_bar_color: '#00a4e4',
    //     });
    // },[]);

    const nextSlide = () => {
        if (slide >= slides.length - 1) {
            return dispatch(openView('main'));
        }

        setSlide((p) => p + 1);
    };

    const openMain = async () => {
        if (isCheck1 && isCheck2) {
            await bridge.send('VKWebAppStorageSet', {
                key: 'onboarding',
                value: '4',
            });
            dispatch(openView('main'));
        }
    };

    const accept1 = () => {
        setIsCheck1(!isCheck1);
    };

    const accept2 = () => {
        setIsCheck2(!isCheck2);
    };

    return (
        <Panel id={id}>
            <div className="welcome">
                <Gallery
                    slideWidth="100%"
                    bullets='light'
                    slideIndex={slide}
                    onChange={setSlide}
                >
                    <div className='welcome-slide welcome-image' style={{ backgroundImage: `url(${welcome01})` }}>
                        <div className="welcome-logo" />
                        <span>Сезон автопутешествий продолжается!</span>
                    </div>
                    <div className='welcome-slide welcome-image' style={{ backgroundImage: `url(${welcome02})` }}>
                        <div className="welcome-logo" />
                        <span>Выберите свой<br/> впечатляющий маршрут</span>
                    </div>
                    <div className='welcome-slide welcome-image' style={{ backgroundImage: `url(${welcome03})` }}>
                        <div className="welcome-logo" />
                        <span>Заправьтесь на АЗС<br/> «Газпромнефть» и вперед!</span>
                        <Checkbox checked={isCheck1} onChange={accept1}>Я принимаю условия <a href='https://drive.google.com/file/d/1UbfvZBhtKoSXG6extcmnxjIRAz1-H-mk/view' target='_blank'>Пользовательского соглашения</a> и <a href='https://drive.google.com/file/d/1L4gtOY6trE2gIT-n-eMdvoCX6tXza27N/view' target='_blank'>Политики конфиденциальности</a></Checkbox>
                        <Checkbox checked={isCheck2} onChange={accept2}>Я согласен на обработку моих персональных данных</Checkbox>
                        <div className={`btn btn-outline${isCheck1 && isCheck2 ? '' : ' btn_disabled'}`} onClick={openMain}>начать <div className="btn-icon arrow-right" /></div>
                    </div>
                </Gallery>
            </div>
        </Panel>
    );
};

export default Welcome;
