import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalPage, Div } from '@vkontakte/vkui';

import { closeModal } from '../../../store/navigation/actions';

import ModalPageHeaderWithButtons from '../ModalPageHeaderWithButtons';

const CalculatorInfo2 = ({ id }) => {
    const dispatch = useDispatch();

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={
                <ModalPageHeaderWithButtons>
                    Информация
                </ModalPageHeaderWithButtons>
            }
        >
            <Div>
                Cогласно правилам участия в программе лояльности физических лиц
                «Нам по пути», п. 3.16:
                Порядок расчёта бонусов, размещённый на сайте www.gpnbonus.ru и в иных информационных материалах Организатора и Партнёра/ Банка-партнера, является примерным. По запросу Участника ему может быть предоставлена более подробная информация о порядке начисления Бонусов.
            </Div>
        </ModalPage>
    );
};

export default CalculatorInfo2;
