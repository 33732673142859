import React from 'react';
import { useDispatch } from 'react-redux';
import {ModalCard, ModalPage} from '@vkontakte/vkui';

import { closeModal } from '../../../store/navigation/actions';

import './index.scss';

const BonusInfo = ({ id, text }) => {
    const dispatch = useDispatch();

    return (
        <ModalPage
            id={id}
            actions={[{
                action: () => dispatch(closeModal()),
            }]}
            header=''
        >
            <div className="bonus-info">
                <div className="bonus-info__close" onClick={() => dispatch(closeModal())} />
                <div className="bonus-info__text" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </ModalPage>
    );
};

export default BonusInfo;
