import React, { useEffect } from "react";
import bridge from "@vkontakte/vk-bridge";
import { useDispatch } from "react-redux";
import { request } from "../../networking/api";
import { Panel } from "@vkontakte/vkui";
import "./index.scss";

import { loadRoutes } from "../../store/routes/actions";
import { openView } from "../../store/navigation/actions";

import LogoIcon from "../../assets/white-logo.png";
import { bridgeSetStatusBar } from "mini-juice/vk";

const Intro = ({ id }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //     bridgeSetStatusBar({
  //         status_bar_style: 'light',
  //         action_bar_color: '#0079C1',
  //         navigation_bar_color: '#0079C1',
  //     });
  // },[]);

  useEffect(() => {
    setTimeout(async () => {
      // const routes = await request('route.getCatalog', {})
      //     .then(({ catalog }) => catalog)
      //     .catch(() => []);
      //
      // dispatch(loadRoutes(routes));
      //
      // if (process.env.NODE_ENV !== 'development') {
      //     const response = await bridge.send('VKWebAppStorageGet', {
      //         keys: ['onboarding']
      //     });
      //
      //     if (response.keys[0].value === '4') {
      //         dispatch(openView('main'));
      //     } else {
      //         dispatch(openView('welcome'));
      //     }
      // } else {
      //     dispatch(openView('main'));
      // }

      dispatch(openView("main"));
    }, 2000);
  }, []);

  return (
    <Panel id={id} centered>
      <div className="intro-screen">
        <img className="intro-screen__logo" src={LogoIcon} alt="" />
      </div>
    </Panel>
  );
};

export default Intro;
