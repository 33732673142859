import * as types from './actionTypes';

const initialState = {
    litres: '',
    sum: '',
    oil: null,
    status: null,
    result: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CALCULATOR_SET_LITERS: {
            const { litres } = action;
            return {
                ...state,
                litres,
            };
        }

        case types.CALCULATOR_SET_SUM: {
            const { sum } = action;
            return {
                ...state,
                sum,
            };
        }

        case types.CALCULATOR_SET_OIL: {
            const { oil } = action;
            return {
                ...state,
                oil,
            };
        }

        case types.CALCULATOR_SET_STATUS: {
            const { status } = action;
            return {
                ...state,
                status,
            };
        }

        case types.CALCULATOR_EXEC: {
            const { litres = 0, sum = 0, oil, status } = state;
            if (oil === null || status === null) return state;

            let increase = 0;
            let bonuses = 0;
            switch (status.value) {
                case 1: {
                    switch (oil.value) {
                        case 1: {
                            increase = 0.5;
                            break;
                        }

                        case 2: {
                            increase = 1;
                            break;
                        }

                        case 3: {
                            increase = 1.25;
                            break;
                        }
                    }

                    break;
                }

                case 2: {
                    switch (oil.value) {
                        case 1: {
                            increase = 0.6;
                            break;
                        }

                        case 2: {
                            increase = 1.25;
                            break;
                        }

                        case 3: {
                            increase = 1.5;
                            break;
                        }
                    }

                    break;
                }

                case 3: {
                    switch (oil.value) {
                        case 1: {
                            increase = 0.7;
                            break;
                        }

                        case 2: {
                            increase = 1.5;
                            break;
                        }

                        case 3: {
                            increase = 2;
                            break;
                        }
                    }

                    break;
                }

                case 4: {
                    switch (oil.value) {
                        case 1: {
                            increase = 1.5;
                            break;
                        }

                        case 2: {
                            increase = 2.5;
                            break;
                        }

                        case 3: {
                            increase = 2.5;
                            break;
                        }
                    }

                    break;
                }
            }

            if (litres < 1200) {
                bonuses = (sum * 0.02) + (litres * increase);
            } else {
                bonuses = (sum * 0.02) + (1200 * increase);
            }

            return {
                ...state,
                result: {
                    bonuses: bonuses.toFixed(2),
                },
            };
        }

        case types.CALCULATOR_RESET: {
            return initialState;
        }

        default: return state;
    }
};