import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, Div } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import FavoriteList from "../../components/FavoriteList";

import { openStory } from '../../store/navigation/actions';

const RoutesCat = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const routes = useSelector((store) => store.routes.routes);
    const selectedCategory = useSelector((store) => store.routes.selectedCategory);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    const filteredRoutes = routes
        .filter((x) => x.categories.includes(selectedCategory))
        .filter((x) => !!user.gpnCity ? x.start_city_name === user.gpnCity : true);

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('routes'))}
                    title={selectedCategory}
                />
            </PanelHeader>
            {filteredRoutes.length > 0 ? (
                <FavoriteList
                    item={filteredRoutes}
                    from="routes-cat"
                />
            ) : (
                <p style={{ paddingTop: 0, marginTop: 0, marginLeft: 30, marginRight: 30, }}>
                    К сожалению, таких маршрутов
                    из вашего города пока нет. Зато есть другие, не менее интересные!
                </p>
            )}
        </Panel>
    );
};

export default RoutesCat;
