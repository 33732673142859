import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { request } from '../../networking/api';
import { Panel, PanelHeader, Tabs, TabsItem } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import RouteInfo from "../../components/RouteInfo";
import RouteTrip from "../../components/RouteTrip";
import './index.scss';

import { openStory, openModal } from '../../store/navigation/actions';
import { selectDay } from '../../store/routes/actions';
import { userUpdate } from "../../store/user/actions";
import GA from "react-ga";

const RoutesInfo = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const route = useSelector((store) => store.routes.selectedRoute);
    const day = useSelector((store) => store.routes.selectedDay);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        if (!user.warnShown) {
            dispatch(userUpdate('warnShown', true));
            dispatch(openModal('route-warn'));
        }
    }, []);

    const makeRouteCool = () => {
        GA.event({
            category: 'routes',
            action: 'make-route-profitable',
        });

        dispatch(openStory('bonuses'));
    };

    const getRoute = () => {
        GA.event({
            category: 'routes',
            action: 'get-route',
        });

        dispatch(openStory('routes-map'))
    };

    if (!route) {
        return (
            <Panel id={id}>
                <PanelHeader separator={false} >
                    <PanelTitle
                        beforeIcon={<div className='icon-back' />}
                        title={route.title.slice(0, 28)}
                    />
                </PanelHeader>
            </Panel>
        );
    }

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory(route.story_from))}
                    title={route.title}
                />
            </PanelHeader>
            <div className="block-wrap routes-info">
                <RouteInfo
                    image={route.img_link}
                    title={route.title}
                    day={route.days.length}
                    distance={Math.round(route.length_car / 1000)}
                    hotels={2}
                    desc={route.description}
                    recommendList={route.highlights.split(`\n`)}
                    is_favourite={!!route.is_favourite}
                    onFavourite={() => {
                        request(`route.${!!route.is_favourite ? 'removeFromFavourites' : 'addToFavourites'}`, {
                            id: route.id,
                        }).then(() => {
                            dispatch({
                                type: !!route.is_favourite ? '@routes/remove-from-favs' : '@routes/add-to-favs',
                                id: route.id,
                            });
                        })
                    }}
                />
                <div className="routes-info__schedule">
                    <div className="routes-info__schedule--title">План поездки</div>
                    <Tabs mode="buttons">
                        {(route.days || []).map((x, k) => (
                            <TabsItem
                                onClick={() => dispatch(selectDay(k))}
                                selected={day === k}
                            >
                                {k + 1} день
                            </TabsItem>
                        ))}
                    </Tabs>
                    <RouteTrip
                        name={route.days[day].title}
                        car={Math.round(route.days[day].length_car / 1000)}
                        walk={route.days[day].length_foot}
                        house='21:00'
                        image={route.days[day].img_link}
                        about={route.days[day].description}
                        item={route.days ? route.days[day].events : []}
                    />
                </div>
                <div className="routes-info__buttons">
                    <div
                        className="btn btn-outline-blue"
                        onClick={makeRouteCool}
                    >
                        сделать маршрут выгодным
                    </div>
                    <div
                        className="btn btn-primary"
                        onClick={getRoute}
                    >
                        получить маршрут
                    </div>
                </div>
            </div>
        </Panel>
    );
};

export default RoutesInfo;
