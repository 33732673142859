import React, {useEffect, useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import { useDispatch, useSelector } from "react-redux";
import {FormLayout, Input, Panel, PanelHeader, Cell, Switch, SelectMimicry} from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import {openModal} from "../../store/navigation/actions";
import './index.scss';

import card from '../../assets/img/card-image.png';

import { openStory } from '../../store/navigation/actions';
import {userUpdate} from "../../store/user/actions";

const Profile = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const [isChangingNotifications, setIsChangingNotifications] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    const switchIt = () => {
        if (!isChangingNotifications) {
            setIsChangingNotifications(true);
            if (user.notify) {
                bridge.send('VKWebAppDenyNotifications')
                    .then(() => {
                        dispatch(userUpdate('notify', false));
                    })
                    .catch(() => {
                        dispatch(userUpdate('notify', true));
                    })
                    .finally(() => {
                        setIsChangingNotifications(false);
                    });
            } else {
                bridge.send('VKWebAppAllowNotifications')
                    .then(() => {
                        dispatch(userUpdate('notify', true));
                    })
                    .catch(() => {
                        dispatch(userUpdate('notify', false));
                    })
                    .finally(() => {
                        setIsChangingNotifications(false);
                    });
            }
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('home'))}
                    title='Профиль'
                />
            </PanelHeader>
            <div className="block-wrap profile">
                <img src={card} alt='' />
                <div className="profile__title">Ваша карта лояльности <span className="bold-blue">«Нам по пути»</span></div>
                <FormLayout className='form-input'>
                    <Input
                        top="Введите номер телефона, привязанный к карте лояльности"
                        type="text"
                        placeholder='+7'
                        pattern="[0-9.]+"
                        value={user.gpnPhone}
                        disabled
                    />
                </FormLayout>
                <div className="btn btn-primary" onClick={() => dispatch(openStory('profile-change-phone'))}>изменить</div>
                <div className="profile__notify">
                    <Cell
                        asideContent={
                            <Switch
                                className='custom-switch'
                                checked={user.notify}
                                onChange={switchIt}
                            />
                        }
                    >
                        Уведомления
                    </Cell>
                </div>
                <div className="profile__city">
                    <b>Город</b>
                    <SelectMimicry
                        placeholder={user.gpnCity}
                        onClick={() => dispatch(openModal('select-city'))}
                    />
                </div>
            </div>
        </Panel>
    );
};

export default Profile;
