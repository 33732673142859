import React from 'react';
import './index.scss';

const TopRoute = () => {
    return (
        <div className="top-route">
            <div className="top-route__logos">
                <div className="top-route__logo top-route__logo_gazprom"/>
                <div className="top-route__logo top-route__logo_travel"/>
            </div>
            <div className="top-route__text">
                Найдите свой идеальный маршрут на выходные в авторской коллекции автопутешествий, которую мы создали с экспертами Road.Travel специально для вас
            </div>
        </div>
    );
};

export default TopRoute;
