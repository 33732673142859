import * as types from './actionTypes';

export const openView = (nextView) => {
    return {
        type: types.NAVIGATION_OPEN_VIEW,
        nextView,
    };
};

export const openModal = (nextModal) => {
    return {
        type: types.NAVIGATION_OPEN_MODAL,
        nextModal,
    };
};

export const closeModal = () => {
    return {
        type: types.NAVIGATION_CLOSE_MODAL,
    };
};

export const openStory = (nextStory) => {
    return {
        type: types.NAVIGATION_OPEN_STORY,
        nextStory,
    };
};