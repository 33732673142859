import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../networking/api';
import { FormLayout, Input, Panel, PanelHeader } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import './index.scss';

import { openStory } from '../../store/navigation/actions';
import {userUpdate} from "../../store/user/actions";
import bridge from "@vkontakte/vk-bridge";

const ProfileChangePhone = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const [ phone, setPhone ] = useState('');
    const [ isPhoneFillAsked, setIsPhoneFillAsked ] = useState(false);
    const [ isPhoneRight, setIsPhoneRight ] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        if (phone.length >= 12) {
            setIsPhoneRight(true);
        } else {
            setIsPhoneRight(false);
        }
    }, [phone]);

    const clickPhoneInput = () => {
        if (!isPhoneFillAsked) {
            setIsPhoneFillAsked(true);
            bridge.send('VKWebAppGetPhoneNumber')
                .then((response) => {
                    if (response.phone_number) {
                        setPhone(`+${response.phone_number}`);
                    }
                })
                .catch(() => {
                    setPhone('+7');
                })
        } else {
            if (phone.length <= 2) {
                setPhone('+7');
            }
        }
    };

    const changePhoneInput = (e) => {
        const value = e.currentTarget.value;
        const newValue = value.replaceAll('.', '');
        if (!(!Number(newValue) || (newValue.length > 2 && !newValue.startsWith('+7')))) {
            setPhone(newValue)
        } else {
            let numb = newValue.match(/\d/g);
            if (numb === null) {
                numb = 7;
            } else {
                numb = numb.join("");
            }
            setPhone(`+${numb}`);
        }
    };

    const clickSave = () => {
        if (phone.length >= 12) {
            request('user.editPhone', { phone, city: user.gpnCity })
                .then(() => {
                    dispatch(userUpdate('gpnPhone', phone));
                    dispatch(openStory('profile'));
                });
        }
    };

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('profile'))}
                    title='Редактирование'
                />
            </PanelHeader>
            <div className="block-wrap profile-change-phone">
                <FormLayout className='form-input'>
                    <Input
                        top="Редактируйте номер телефона, привязанный к карте лояльности"
                        type="tel"
                        placeholder='+7 (900) 100 20 30'
                        maxLength={12}
                        value={phone}
                        onClick={clickPhoneInput}
                        onChange={changePhoneInput}
                    />
                </FormLayout>
                <div
                    className={`btn btn-primary${!isPhoneRight ? ' btn-disabled' : ''}`}
                    onClick={clickSave}
                >
                    сохранить
                </div>
            </div>
        </Panel>
    );
};

export default ProfileChangePhone;
