import * as types from './actionTypes';

export const setLitres = (litres) => {
    return {
        type: types.CALCULATOR_SET_LITERS,
        litres,
    };
};

export const setSum = (sum) => {
    return {
        type: types.CALCULATOR_SET_SUM,
        sum,
    };
};

export const setOil = (oil) => {
    return {
        type: types.CALCULATOR_SET_OIL,
        oil,
    };
};

export const setStatus = (status) => {
    return {
        type: types.CALCULATOR_SET_STATUS,
        status,
    };
};

export const exec = () => {
    return {
        type: types.CALCULATOR_EXEC,
    };
};

export const reset = () => {
    return {
        type: types.CALCULATOR_RESET,
    };
};
