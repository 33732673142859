import * as types from './actionTypes';

export const loadUser = (data = null) => {
    return {
        type: types.USER_LOAD,
        data,
    };
};

export const userUpdate = (field, value) => {
    return {
        type: types.USER_UPDATE,
        field,
        value,
    }
};