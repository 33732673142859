import * as types from './actionTypes';

const initialState = {
    activeView: 'intro',
    activeStory: 'home',
    activeModal: null,
    is_from_route: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NAVIGATION_OPEN_VIEW: {
            const { nextView } = action;
            return {
                ...state,
                activeView: nextView,
            };
        }

        case types.NAVIGATION_OPEN_MODAL: {
            const { nextModal } = action;
            return {
                ...state,
                activeModal: nextModal,
            };
        }

        case types.NAVIGATION_CLOSE_MODAL: {
            return {
                ...state,
                activeModal: null,
            };
        }

        case types.NAVIGATION_OPEN_STORY: {
            const { nextStory } = action;
            return {
                ...state,
                activeStory: nextStory,
                is_from_route: state.activeStory === 'routes-info',
            };
        }

        default: return state;
    }
};
