import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Panel, PanelHeader, Cell, Switch } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import './index.scss';

import card from '../../assets/img/card-image.png';

import { openStory } from '../../store/navigation/actions';
import bridge from "@vkontakte/vk-bridge";

const ProfileNoCard = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('home'))}
                    title='Профиль'
                />
            </PanelHeader>
            <div className="block-wrap profile-no-card">
                <img src={card} alt='' />
                <div className="profile-no-card__title">Номер, привязанный к карте лояльности, не указан</div>
                <div
                    className="btn btn-primary"
                    onClick={() => dispatch(openStory('routes-login'))}
                >
                    авторизоваться
                </div>
                <div className="profile__notify">
                    <Cell
                        asideContent={
                            <Switch
                                className='custom-switch'
                                defaultChecked={user.notify}
                                onChange={() => {
                                    bridge.send(user.notify ? 'VKWebAppDenyNotifications' : 'VKWebAppAllowNotifications');
                                }}
                            />
                        }
                    >
                        Уведомления
                    </Cell>
                </div>
            </div>
        </Panel>
    );
};

export default ProfileNoCard;
