import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import FavoriteList from "../../components/FavoriteList";
import './index.scss';

import { openStory } from '../../store/navigation/actions';

const RoutesFavorite = ({ id }) => {
    const dispatch = useDispatch();
    const favourites = useSelector((store) => store.routes.routes.filter((x) => !!x.is_favourite));

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-back' />}
                    onClick={() => dispatch(openStory('routes'))}
                    title='Избранные маршруты'
                />
            </PanelHeader>
            <FavoriteList showFavBtn item={favourites} from="routes-favorite"/>
            {favourites.length === 0 && <p style={{ paddingTop: 0, marginTop: 0, marginLeft: 30, marginRight: 30 }}>Добавьте понравившийся маршрут в Избранное, нажав на «сердечко» в правом верхнем углу на фото маршрута</p>}
        </Panel>
    );
};

export default RoutesFavorite;
