import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalPage, List, Cell } from '@vkontakte/vkui';
import oils from '../../../data/oils.json';

import { closeModal } from '../../../store/navigation/actions';
import { setOil } from '../../../store/calculator/actions';

import ModalPageHeaderWithButtons from '../ModalPageHeaderWithButtons';

const SelectOil = ({ id }) => {
    const dispatch = useDispatch();
    const currentOil = useSelector((store) => store.calculator.oil);

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            header={
                <ModalPageHeaderWithButtons>
                    Топливо
                </ModalPageHeaderWithButtons>
            }
        >
            <List>
                {oils.map((oil) => (
                    <Cell
                        key={oil.text}
                        multiline
                        onClick={() => {
                            dispatch(setOil(oil));
                            dispatch(closeModal());
                        }}
                        indicator={
                            currentOil !== null && currentOil.text === oil.text && <div className='icon-check' />
                        }
                    >

                        {oil.text}
                    </Cell>
                ))}
            </List>
        </ModalPage>
    );
};

export default SelectOil;
