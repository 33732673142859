import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ModalRoot } from '@vkontakte/vkui';

import SelectOil from './SelectOil';
import SelectStatus from './SelectStatus';
import {closeModal} from "../../store/navigation/actions";
import CityAsk from "./CityAsk";
import './index.scss';
import BonusInfo from "./BonusInfo";
import SelectCity from "./SelectCity";
import CalculatorInfo from './CalculatorInfo';
import CalculatorInfo2 from './CalculatorInfo2';
import RouteWarning from "./RouteWarning";
import Share from "./Share";
import MapInfo from "./MapInfo";

const Modals = () => {
    const dispatch = useDispatch();
    const activeModal = useSelector((store) => store.navigation.activeModal);

    return (
        <ModalRoot activeModal={activeModal} onClose={() => dispatch(closeModal())}>
            <SelectOil id="select-oil" />
            <SelectStatus id="select-status" />
            <CityAsk id='city-ask' />
            <SelectCity id='select-city' />
            <BonusInfo id='bonus-double' text='Акция «Двойные бонусы» проводится с 09.11.2020 г. по 31.01.2021 г. (вкл.) на АЗС в сети «Газпромнефть» на территории РФ. Организатор акции ООО «Газпромнефть-Центр». Для получения двойных бонусов необходимо в период акции совершить покупку топлива от 30 литров и более, оплатить наличными или банковской картой с применением карты «Нам по пути» (далее – ПЛ) и получить в 2 раза больше бонусов по сравнению с обычным начислением в зависимости от статуса карты ПЛ (согласно Приложения 2 к Правилам ПЛ). В акции участвуют: АИ-92, АИ-95, АИ-98, АИ-92 ОПТИ,АИ-95 ОПТИ, G-Drive-95, G-Drive 98, G-Drive 100, ДТ, «Дизель ОПТИ», G-Drive Diesel, СУГ. Подробнее об организаторе, Правилах акции, участвующих АЗС, ограничениях и исключениях - на сайте www.gpnbonus.ru. и по телефону 8 800 700 51 51 (звонок по России бесплатный).' />
            <BonusInfo id='bonus-50' text='При заправке не менее 50 литров в месяц.' />
            <BonusInfo id='bonus-100' text='1) В мобильном приложении АЗС «Газпромнефть» откройте личный<br/> кабинет и нажмите «Пригласить<br/> друга»;<br/> 2) Отправьте другу ссылку на регистрацию;<br/> 3) Когда он зарегистрируется и<br/> заправится в первый раз с картой<br/> лояльности, вы и друг получите по 100 бонусов.' />
            <BonusInfo id='bonus-share' text='1) В личном кабинете в мобильном<br/> приложении нажать кнопку меню<br/> «Перевод бонусов»<br/> 2) Введите номер карты лояльности<br/> получателя и сумму бонусов для<br/> перевода' />
            <CalculatorInfo id="calc-info" />
            <CalculatorInfo2 id="calc-info-2" />
            <RouteWarning id="route-warn" />
            <Share id="share" />
            <MapInfo id="map-info" />
        </ModalRoot>
    );
};

export default Modals;
