import * as types from './actionTypes';

const initialState = {
    loaded: false,
    data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.USER_LOAD: {
            const { data } = action;
            return {
                ...state,
                loaded: true,
                data,
            }
        }

        case types.USER_UPDATE: {
            const { field, value } = action;
            return {
                ...state,
                data: {
                    ...state.data,
                    [field]: value,
                }
            }
        }

        default: return state;
    }
};