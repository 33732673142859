import React,{ useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader } from '@vkontakte/vkui';
import PanelTitle from "../../components/PanelTitle";
import RoutesList from "../../components/RoutesList";
import './index.scss';

import { openStory, openModal } from '../../store/navigation/actions';
import TopRoute from "../../components/TopRoute";

const Routes = ({ id }) => {
    const dispatch = useDispatch();
    const user = useSelector((store) => store.user.data);
    const categories = useSelector((store) => store.routes.categories);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, []);

    useEffect(() => {
        if (!user.gpnCity) {
            dispatch(openModal('city-ask'));
        }
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader separator={false} >
                <PanelTitle
                    beforeIcon={<div className='icon-fav' />}
                    onClick={() => dispatch(openStory('routes-favorite'))}
                    title='Маршруты'
                />
            </PanelHeader>
            <TopRoute/>
            <RoutesList items={categories || []} />
        </Panel>
    );
};

export default Routes;
