import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {wordPad} from "../../utils/_functions";

import { selectRoute } from '../../store/routes/actions';
import { openStory } from '../../store/navigation/actions';

const FavoriteList = ({ item, showFavBtn, from }) => {
    const dispatch = useDispatch();

    return (
        item.length > 0 &&
            <div className="block-wrap">
                <div className="routes-favorite">
                    {item.map((favorite, i) => (
                        <div
                            key={i}
                            onClick={() => {
                                dispatch(selectRoute(favorite.id, from));
                                dispatch(openStory('routes-info'));
                            }}
                            className="routes-favorite__item"
                        >
                            {showFavBtn ?
                                <div
                                    className={`routes-favorite__item--fav ${!!favorite.is_favourite ? 'active' : ''}`}
                                    onClick={favorite.fav}>
                                    <div className='icon-fav'/>
                                </div>
                                : ''}
                            <div className="routes-favorite__item--content"
                                 style={{backgroundImage: `url(${favorite.img_link})`}}
                            />
                            <div className="routes-favorite__item--title-and-day">
                                <div className="routes-favorite__item--title">{favorite.title}</div>
                                <div className="routes-favorite__item--day btn btn-outline small">
                                    {favorite.days.length} {wordPad(favorite.days.length, ' день', ' дня', ' дней')}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default FavoriteList;
