import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalPageHeader, PanelHeaderButton } from '@vkontakte/vkui';


import { closeModal } from '../../../store/navigation/actions';

const ModalPageHeaderWithButtons = ({ children, onClose = null }) => {
    const dispatch = useDispatch();

    return (
        <ModalPageHeader
            right={<PanelHeaderButton onClick={() => {
                if (onClose !== null) onClose();
                dispatch(closeModal());
            }}>
                <div className='icon-close' />
            </PanelHeaderButton>}
        >
            {children}
        </ModalPageHeader>
    );
};

export default ModalPageHeaderWithButtons;
