import bridge from '@vkontakte/vk-bridge';

const API_HOST = `https://staging.gazprom.vk-admin.com`;

export const request = (method, params) => {
    const formData = new FormData();
    formData.append('url', window.location.href);
    Object.keys(params).forEach((key) => formData.append(key, params[key]));

    return fetch(`${API_HOST}/${method}`, {
        method: 'POST',
        body: formData,
    })
        .then((res) => res.json())
        .then((res) => {
            const { result, ...data } = res;

            if (result !== 'success') {
                throw new Error(`[API][${method}]: Error ${res.error}`);
            }

            return data;
        });
};

/*
    Возвращает основную информацию о профиле
 */
export const VKGetProfile = async () => {
    return await bridge.send('VKWebAppGetUserInfo', {})
        .catch(() => null);
};
