import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalPage, Div } from '@vkontakte/vkui';

import { closeModal } from '../../../store/navigation/actions';

import WarningWeather from '../../../assets/img/warning-weather.svg';
import WarningCovid from '../../../assets/img/warning-covid.svg';
import ModalPageHeaderWithButtons from "../ModalPageHeaderWithButtons";

const RouteWarning = ({ id }) => {
    const dispatch = useDispatch();

    return (
        <ModalPage
            id={id}
            onClose={() => dispatch(closeModal())}
            settlingHeight={100}
            header={
                <ModalPageHeaderWithButtons>
                    Информация
                </ModalPageHeaderWithButtons>
            }
        >
            <Div style={{ padding: '20px' }}>
                <img height='68px' src={WarningWeather} alt="" draggable={false} style={{ marginBottom: '-10px'}} />
                <p>
                    <b>Предложенные маршруты, их состав и расписание носят рекомендательный характер.</b>
                    <br/>Вы можете корректировать маршрут по вашему желанию в соответствии с погодными, дорожными и прочими внешними условиями. По своему усмотрению Вы можете разделить маршрут на большее количество дней.
                </p>
                <div style={{ height: 15 }} />
                <img height='50px' src={WarningCovid} alt="" draggable={false} />
                <p>
                    <b>При планировании путешествия, пожалуйста, учитывайте текущую эпидемиологическую ситуацию в вашем регионе и регионе назначения.</b>
                    <br/>Перед поездкой дополнительно уточните доступность и правила посещения достопримечтальностей на маршруте – они могут измениться
                </p>
            </Div>
        </ModalPage>
    );
};

export default RouteWarning;
