import React from 'react';
import { useDispatch } from 'react-redux';
import './index.scss';

import rest from '../../assets/img/route-rest.jpg';
import { openStory } from '../../store/navigation/actions';
import { selectCategory } from '../../store/routes/actions';

const RoutesList = ({ items }) => {
    const dispatch = useDispatch();

    return (
        <div className="block-wrap">
            <div className="routes-list">
                {items.map((category, i) => (
                    <div
                        key={category.id}
                        className="routes-list__item"
                        style={{ backgroundImage: `url(/assets/categories/${category.icon})` }}
                        onClick={() => {
                            dispatch(selectCategory(category.id));
                            dispatch(openStory('routes-cat'));
                        }}
                    >
                        <div className="routes-list__item--title">{category.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RoutesList;
